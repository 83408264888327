<template>
  <el-dialog
    title="Отправка уведомлений"
    visible
    width="640px"
    append-to-body
    :before-close="close"
    class="r-modal-window"
  >
    <r-block
      v-loading="loading"
      no-padding
    >
      <r-text> Тема </r-text>
      <el-input
        v-model="subject"
        class="r-input"
        placeholder="Введите тему письма"
        @input="hasChanges = true"
      />
      <r-text> Сообщение </r-text>
      <el-input
        v-model="massage"
        class="r-input"
        type="textarea"
        :rows="4"
        placeholder="Введите сообщение"
        @input="hasChanges = true"
      />
      <div class="ed-create__form-item">
        <file-uploader @fileList="fileListHandler" />
      </div>
      <r-block
        space-between
        no-padding
        col
      >
        <r-text>Список адресов</r-text>
        <r-button
          icon="add-plus"
          :disabled="addDisabled"
          @click="addNew"
        />
      </r-block>
      <div
        v-for="(email, i) in emails"
        :key="email.id"
        class="mail-model__item"
        no-padding
        stretch
        col
      >
        <r-text>{{ i + 1 }}.</r-text>
        <el-input
          v-model="email.email"
          class="r-input"
          placeholder="Введите адрес"
          @input="hasChanges = true"
        />
        <r-button
          icon="trash"
          simple
          @click="removeEmail(email.id)"
        />
      </div>
      <r-block
        no-padding
        col
      >
        <r-button
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="sendMail"
        >
          Отправить
        </r-button>
        <r-button
          simple
          @click="close"
        >
          Отмена
        </r-button>
      </r-block>
    </r-block>
  </el-dialog>
</template>

<script>
import { notifyFactory } from '@/utils'
import fileUploader from '@/components/distributions/email-distributions/file-uploader.vue'

export default {
  components: { fileUploader },
  props: {
    module: {
      type: String,
      required: true
    },
    objectIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      hasChanges: false,
      emails: [{ id: 1, email: '' }],
      massage: this.defaultMessage(),
      subject: '',
      fileList: []
    }
  },
  computed: {
    addDisabled() {
      return this.emails.some(e => !e.email?.trim())
    }
  },
  methods: {
    close() {
      if (this.hasChanges) {
        const title = this.$t('mail-modal:' + 'before:title')
        const message = this.$t('mail-modal:' + 'before-cancel:text')
        const confirmButtonText = this.$t('mail-modal:' + 'button-confirm')
        const cancelButtonText = this.$t('mail-modal:' + 'button-cancel')

        this.$confirm(message, title, {
          customClass: 'r-message-box',
          closeOnPressEscape: true,
          closeOnClickModal: false,
          type: 'warning',
          confirmButtonText,
          cancelButtonText
        })
          .then(() => {
            this.$emit('close')
          })
      } else {
        this.$emit('close')
      }
    },
    addNew() {
      const lastId = this.emails.reduce((a, c) => {
        if (c.id >= a) {
          a = c.id + 1
        }
        return a
      }, 1)
      this.emails.push({ id: lastId, email: '' })
    },
    removeEmail(id) {
      const index = this.emails.findIndex(e => e.id === id)

      if (index > -1) {
        this.emails.splice(index, 1)
      }
    },
    emailUrl() {
      switch (this.module) {
        case 'videophoto':
          return 'event_camera_traffic?format=email'
        case 'car_event':
          return 'event_car_events?format=email'
        case 'acts':
          return 'act_events?format=email'
        case 'register':
          return 'odd_events?format=email'
        default:
          return 'event_camera_traffic?format=email'
      }
    },
    defaultMessage() {
      switch (this.module) {
        case 'videophoto':
          return 'Уведомляем вас о нарушении(ях)\n' +
            'Информация предоставлена во вложении\n' +
            'Просим ознакомиться и принять меры\n' +
            ' \n' +
            'Сообщение сформировано в автоматическом режиме, ответа на него не требуется.\n' +
            ' \n' +
            'С уважением,\n' +
            'Безопасность дорожного движения.'
        case 'acts':
          return 'Направляем в ваш адрес Акт о нарушении\n' +
          'Акт во вложении\n' +
          'Просим ознакомиться и подписать.\n' +
          ' \n' +
          'Сообщение сформировано в автоматическом режиме, ответа на него не требуется.\n' +
          ' \n' +
          'С уважением,\n' +
          'Безопасность дорожного движения.'
        case 'car_event':
          return 'Уведомляем вас о нарушении(ях)\n' +
            'Информация предоставлена во вложении\n' +
            'Просим ознакомиться и принять меры\n' +
            ' \n' +
            'Сообщение сформировано в автоматическом режиме, ответа на него не требуется.\n' +
            ' \n' +
            'С уважением,\n' +
            'Безопасность дорожного движения.'
        case 'register':
          return 'Уведомляем вас о закрытии участков дорог.\n' +
            'Карточки событий во вложении.\n' +
            'Просим ознакомиться и использовать в работе.\n' +
            ' \n' +
            'Сообщение сформировано в автоматическом режиме, ответа на него не требуется.\n' +
            ' \n' +
            'С уважением,\n' +
            'Безопасность дорожного движения.'
        default:
          return ''
      }
    },
    fileListHandler(list) {
      this.fileList = list.map(f => f.response.resources_ids[0])
    },
    async sendMail() {
      try {
        this.loading = true

        if (!this.massage?.trim()) {
          this.$notify(notifyFactory('warning', 'Уведомление', 'Сообщение не может быть пустым'))

          return
        }

        if (this.addDisabled) {
          this.$notify(notifyFactory('warning', 'Уведомление', 'Необходимо заполнить все поля адресов'))

          return
        }

        const data = {
          subject: this.subject,
          massage: this.massage,
          emails: this.emails?.map(e => e.email),
          ids: this.objectIds,
          resources: this.fileList
        }

        await this.$store.dispatch('POST_REQUEST', {
          url: this.emailUrl(),
          data
        })

        this.hasChanges = false
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mail-model {
  &__item {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto 1fr auto;
    align-content: center;
    align-items: center;
  }
}
</style>
